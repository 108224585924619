<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >

                <template v-if="allowCreateMeeting">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>

                        <v-list-item
                            link
                            :to="{ name: 'MeetingsCalendar' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-calendar-alt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Календарь_мероприятий") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'MeetingsList' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-list-alt</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Список_мероприятий") }}</v-list-item-title>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$route.fullPath" />
            
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "MeetingModul",
    components: {
    },
    computed: {
        ...mapGetters({drawerLeftMenuMini: 'isDrawerLeftMenuMinimized' }),
        allowCreateMeeting(){
            return this.$store.getters['global/auth/getUserInfo'].Permissions.includes("CreateMeeting");
        }
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,
        group1: {
            active: false
        },
        group2: {
            active: false
        },
        group3: {
            active: false
        }
    }),    
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            await this.loadDataSource({ createType: "None.Meeting", navigate: true, common: false });
        },
        closeOtherGroups(groupName) {
            if (groupName === 'group1') {
                this.group2.active = false;
                this.group3.active = false;
            } else if (groupName === 'group2') {
                this.group1.active = false;
                this.group3.active = false;
            }else if (groupName === 'group3') {
                this.group1.active = false;
                this.group2.active = false;
            }
        }
    }
}
</script>